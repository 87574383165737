import React from 'react'

const Social = () => {
    return (
        <div>
            <ul className="icons">
                <li><a href="https://twitter.com/pathegarty360" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/paddy.hegarty2" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/paddy.hegarty/" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                <li><a href="https://github.com/paddyhegarty" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/in/patrickhegarty1/" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
        </div>
    )
}

export default Social


